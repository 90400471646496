import * as React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

export default function AllOnlyToggle({allOrOnly, setAllOrOnly}) {
  

  const handleChange = (event, newAllOrOnly) => {
    setAllOrOnly(newAllOrOnly);
  };

  return (
    <ToggleButtonGroup
      color="primary"
      value={allOrOnly}
      exclusive
      onChange={handleChange}
      aria-label="Platform"
    >
      <ToggleButton value="ALL">ALL</ToggleButton>
      <ToggleButton value="ONLY">ONLY</ToggleButton>
    </ToggleButtonGroup>
  );
}