import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { Link } from "react-router-dom";
import { styled } from '@mui/material/styles';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function Home() {
    return (
        <Container maxWidth="sm" sx={{ marginY: 5 }}>
            <Box sx={{ width: '100%' }}>
                <Stack spacing={2}>
                            <Link key="Charts" to="/chart">
                                <Item>Stats</Item>
                            </Link>
                            <Link key="Tags" to="/tag">
                                <Item>Tags</Item>
                            </Link>
                </Stack>
            </Box>
        </Container>
    );
}

