import './App.css';
import { useState } from "react";
import Index from "./pages/Index";
import { createTheme, ThemeProvider } from "@mui/material";
function App() {
  const [isLoggedIn, setLoggedIn] = useState(false);

  const theme = createTheme({
    palette: {
      primary: {
        // Purple and green play nicely together.
        main: '#d32f2f',
      },
      secondary: {
        // This is green.A700 as hex.
        main: '#388e3c',
      },
    },

    components: {
      MuiTypography: {
        variants: [
          {
            props: {
              variant: "body2"
            },
            style: {
              fontSize: 11,
            }
          },
          {
            props: {
              variant: "body3"
            },
            style: {
              fontSize: 9,
            }
          }

        ]
      }
    }
  })

  return (
    <>
        <ThemeProvider theme={theme}>
          <Index isLoggedIn={isLoggedIn} setLoggedIn={setLoggedIn} />
        </ThemeProvider>
    </>

  );
}

export default App;