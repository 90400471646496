
import axios from 'axios';

const baseUrl = process.env.NODE_ENV === "production" || process.env.REACT_APP_NODE_ENV === "prod" ? 'https://jzpgjzcvei.execute-api.eu-west-1.amazonaws.com/dev/api/v1/'
  : 'http://localhost:3030/api/v1';

const api = axios.create(
  {
    baseURL: baseUrl,
  }
);

api.interceptors.request.use((config) => {

  console.log(baseUrl);

  return config;
},

  (error) => {
    return Promise.reject(error);
  }
);


export const getCannedData = () => statsData;

export const getData = (session, players) => api.get(`/rawstats?session=${session}&players=${players}`).then((res) => { console.log(JSON.stringify(res)); return res.data; });

export const getPlayers = (session, allOnlyToggle) => api.get(`/rawstats/players?session=${session}&allOnly=${allOnlyToggle}`).then((res) => { console.log(JSON.stringify(res)); return res.data; });

export const getSessions = () => api.get('/rawstats/session').then((res) => { console.log(JSON.stringify(res)); return res.data; });

export const getRawtag = (session, source, userKey) => api.get(`/rawtags?session=${session}&source=${source}&userKey=${userKey}`).then((res) => { console.log(JSON.stringify(res)); return res.data; });

export const updateRawtag = (id, rawtag) => api.put(`/rawtags/${id}`, rawtag).then((res) => { console.log(JSON.stringify(res)); return res.data; });

export const createRawtag = (rawtag) => api.post(`/rawtags/`, rawtag).then((res) => { console.log(JSON.stringify(res)); return res.data; });

export const getRawtagSessions = () => api.get('rawtags/session').then((res) => { console.log(JSON.stringify(res)); return res.data; });

const statsData = [
  {
    "PlayerId": 7,
    "PlayerInitials": "LH",
    "PlayerName": "Liam Harrington",
    "Device": "GPS1",
    "SquadName": "Adrigole GPS",
    "SessionDate": "2023-04-05",
    "SessionName": "TRAINING-20230405",
    "Drill": "",
    "TotalDistance": 5658,
    "HSR": 1023,
    "DistPerMin": 63,
    "MaxSpeed": 8.1,
    "HighIntensityDistance": 1404,
    "NoSprints": 23,
    "SprintDistance": 862,
    "Accelerations": 38,
    "Decelerations": 19,
    "Calories": 734,
    "HSRPerMin": 11,
    "HIDPerMin": 16,
    "SprintDistancePerMin": 10,
    "StepBalanceL": 51,
    "StepBalanceR": 49,
    "DSL": 326,
    "MaxHR": 0,
    "AvgHR": 0,
    "TimeInRedZone": "00:00:00"
  },
  {
    "PlayerId": 8,
    "PlayerInitials": "CJO",
    "PlayerName": "Charlie Joe O'S",
    "Device": "GPS2",
    "SquadName": "Adrigole GPS",
    "SessionDate": "2023-04-05",
    "SessionName": "TRAINING-20230405",
    "Drill": "",
    "TotalDistance": 6632,
    "HSR": 708,
    "DistPerMin": 74,
    "MaxSpeed": 7.77,
    "HighIntensityDistance": 1210,
    "NoSprints": 19,
    "SprintDistance": 553,
    "Accelerations": 41,
    "Decelerations": 37,
    "Calories": 841,
    "HSRPerMin": 8,
    "HIDPerMin": 13,
    "SprintDistancePerMin": 6,
    "StepBalanceL": 49,
    "StepBalanceR": 51,
    "DSL": 340,
    "MaxHR": 0,
    "AvgHR": 0,
    "TimeInRedZone": "00:00:00"
  },
  {
    "PlayerId": 9,
    "PlayerInitials": "CoC",
    "PlayerName": "Cormac Carey",
    "Device": "GPS3",
    "SquadName": "Adrigole GPS",
    "SessionDate": "2023-04-05",
    "SessionName": "TRAINING-20230405",
    "Drill": "",
    "TotalDistance": 6177,
    "HSR": 852,
    "DistPerMin": 69,
    "MaxSpeed": 7.71,
    "HighIntensityDistance": 1235,
    "NoSprints": 22,
    "SprintDistance": 732,
    "Accelerations": 38,
    "Decelerations": 21,
    "Calories": 783,
    "HSRPerMin": 9,
    "HIDPerMin": 14,
    "SprintDistancePerMin": 8,
    "StepBalanceL": 49,
    "StepBalanceR": 51,
    "DSL": 191,
    "MaxHR": 0,
    "AvgHR": 0,
    "TimeInRedZone": "00:00:00"
  },
  {
    "PlayerId": 11,
    "PlayerInitials": "SOS",
    "PlayerName": "Seanie O'Sullivan",
    "Device": "GPS5",
    "SquadName": "Adrigole GPS",
    "SessionDate": "2023-04-05",
    "SessionName": "TRAINING-20230405",
    "Drill": "",
    "TotalDistance": 6097,
    "HSR": 1044,
    "DistPerMin": 68,
    "MaxSpeed": 7.86,
    "HighIntensityDistance": 1384,
    "NoSprints": 24,
    "SprintDistance": 842,
    "Accelerations": 32,
    "Decelerations": 18,
    "Calories": 769,
    "HSRPerMin": 12,
    "HIDPerMin": 15,
    "SprintDistancePerMin": 9,
    "StepBalanceL": 49,
    "StepBalanceR": 51,
    "DSL": 330,
    "MaxHR": 0,
    "AvgHR": 0,
    "TimeInRedZone": "00:00:00"
  },
  {
    "PlayerId": 12,
    "PlayerInitials": "BOS",
    "PlayerName": "Ben O'Sullivan",
    "Device": "GPS6",
    "SquadName": "Adrigole GPS",
    "SessionDate": "2023-04-05",
    "SessionName": "TRAINING-20230405",
    "Drill": "",
    "TotalDistance": 6360,
    "HSR": 1126,
    "DistPerMin": 71,
    "MaxSpeed": 8.79,
    "HighIntensityDistance": 1490,
    "NoSprints": 26,
    "SprintDistance": 1017,
    "Accelerations": 43,
    "Decelerations": 36,
    "Calories": 822,
    "HSRPerMin": 13,
    "HIDPerMin": 17,
    "SprintDistancePerMin": 11,
    "StepBalanceL": 52,
    "StepBalanceR": 48,
    "DSL": 198,
    "MaxHR": 0,
    "AvgHR": 0,
    "TimeInRedZone": "00:00:00"
  },
  {
    "PlayerId": 10,
    "PlayerInitials": "TS",
    "PlayerName": "Tiernan Sullivan",
    "Device": "GPS4",
    "SquadName": "Adrigole GPS",
    "SessionDate": "2023-04-05",
    "SessionName": "TRAINING-20230405",
    "Drill": "",
    "TotalDistance": 5752,
    "HSR": 703,
    "DistPerMin": 64,
    "MaxSpeed": 7.79,
    "HighIntensityDistance": 1106,
    "NoSprints": 16,
    "SprintDistance": 529,
    "Accelerations": 33,
    "Decelerations": 14,
    "Calories": 722,
    "HSRPerMin": 8,
    "HIDPerMin": 12,
    "SprintDistancePerMin": 6,
    "StepBalanceL": 50,
    "StepBalanceR": 50,
    "DSL": 105,
    "MaxHR": 0,
    "AvgHR": 0,
    "TimeInRedZone": "00:00:00"
  },
  {
    "PlayerId": 4,
    "PlayerInitials": "COS",
    "PlayerName": "Connor O'Shea",
    "Device": "GPS4",
    "SquadName": "Adrigole GPS",
    "SessionDate": "2023-03-29",
    "SessionName": "MACROOM",
    "Drill": "",
    "TotalDistance": 6938,
    "HSR": 1018,
    "DistPerMin": 58,
    "MaxSpeed": 7.89,
    "HighIntensityDistance": 1351,
    "NoSprints": 18,
    "SprintDistance": 972,
    "Accelerations": 20,
    "Decelerations": 8,
    "Calories": 845,
    "HSRPerMin": 8,
    "HIDPerMin": 11,
    "SprintDistancePerMin": 8,
    "StepBalanceL": 50,
    "StepBalanceR": 50,
    "DSL": 293,
    "MaxHR": 0,
    "AvgHR": 0,
    "TimeInRedZone": "00:00:00"
  },
  {
    "PlayerId": 5,
    "PlayerInitials": "GOS",
    "PlayerName": "Gerard O'Shea",
    "Device": "GPS5",
    "SquadName": "Adrigole GPS",
    "SessionDate": "2023-03-29",
    "SessionName": "MACROOM",
    "Drill": "",
    "TotalDistance": 7261,
    "HSR": 1081,
    "DistPerMin": 61,
    "MaxSpeed": 8.01,
    "HighIntensityDistance": 1522,
    "NoSprints": 23,
    "SprintDistance": 1044,
    "Accelerations": 41,
    "Decelerations": 27,
    "Calories": 921,
    "HSRPerMin": 9,
    "HIDPerMin": 13,
    "SprintDistancePerMin": 9,
    "StepBalanceL": 51,
    "StepBalanceR": 49,
    "DSL": 107,
    "MaxHR": 0,
    "AvgHR": 0,
    "TimeInRedZone": "00:00:00"
  },
  {
    "PlayerId": 6,
    "PlayerInitials": "DH",
    "PlayerName": "David Harrington",
    "Device": "GPS6",
    "SquadName": "Adrigole GPS",
    "SessionDate": "2023-03-29",
    "SessionName": "MACROOM",
    "Drill": "",
    "TotalDistance": 7271,
    "HSR": 1070,
    "DistPerMin": 61,
    "MaxSpeed": 8.46,
    "HighIntensityDistance": 1546,
    "NoSprints": 23,
    "SprintDistance": 1006,
    "Accelerations": 41,
    "Decelerations": 34,
    "Calories": 926,
    "HSRPerMin": 9,
    "HIDPerMin": 13,
    "SprintDistancePerMin": 8,
    "StepBalanceL": 50,
    "StepBalanceR": 50,
    "DSL": 279,
    "MaxHR": 0,
    "AvgHR": 0,
    "TimeInRedZone": "00:00:00"
  },
  {
    "PlayerId": 1,
    "PlayerInitials": "DOS",
    "PlayerName": "Darragh O'Sullivan",
    "Device": "GPS1",
    "SquadName": "Adrigole GPS",
    "SessionDate": "2023-03-29",
    "SessionName": "MACROOM",
    "Drill": "",
    "TotalDistance": 7093,
    "HSR": 1121,
    "DistPerMin": 59,
    "MaxSpeed": 9.23,
    "HighIntensityDistance": 1476,
    "NoSprints": 20,
    "SprintDistance": 1105,
    "Accelerations": 30,
    "Decelerations": 18,
    "Calories": 879,
    "HSRPerMin": 9,
    "HIDPerMin": 12,
    "SprintDistancePerMin": 9,
    "StepBalanceL": 48,
    "StepBalanceR": 52,
    "DSL": 292,
    "MaxHR": 80,
    "AvgHR": 80,
    "TimeInRedZone": "00:00:00"
  },
  {
    "PlayerId": 3,
    "PlayerInitials": "FC",
    "PlayerName": "Fergal Carey",
    "Device": "GPS3",
    "SquadName": "Adrigole GPS",
    "SessionDate": "2023-03-29",
    "SessionName": "MACROOM",
    "Drill": "",
    "TotalDistance": 7167,
    "HSR": 997,
    "DistPerMin": 60,
    "MaxSpeed": 7.85,
    "HighIntensityDistance": 1398,
    "NoSprints": 21,
    "SprintDistance": 936,
    "Accelerations": 29,
    "Decelerations": 25,
    "Calories": 895,
    "HSRPerMin": 8,
    "HIDPerMin": 12,
    "SprintDistancePerMin": 8,
    "StepBalanceL": 49,
    "StepBalanceR": 51,
    "DSL": 210,
    "MaxHR": 0,
    "AvgHR": 0,
    "TimeInRedZone": "00:00:00"
  },
  {
    "PlayerId": 2,
    "PlayerInitials": "CianS",
    "PlayerName": "Cian O'Shea",
    "Device": "GPS2",
    "SquadName": "Adrigole GPS",
    "SessionDate": "2023-03-29",
    "SessionName": "MACROOM",
    "Drill": "",
    "TotalDistance": 7504,
    "HSR": 885,
    "DistPerMin": 63,
    "MaxSpeed": 8.22,
    "HighIntensityDistance": 1412,
    "NoSprints": 22,
    "SprintDistance": 832,
    "Accelerations": 43,
    "Decelerations": 31,
    "Calories": 952,
    "HSRPerMin": 7,
    "HIDPerMin": 12,
    "SprintDistancePerMin": 7,
    "StepBalanceL": 49,
    "StepBalanceR": 51,
    "DSL": 169,
    "MaxHR": 0,
    "AvgHR": 0,
    "TimeInRedZone": "00:00:00"
  },
  {
    "PlayerId": 13,
    "PlayerInitials": "CMG",
    "PlayerName": "Cathal Mike Gerry",
    "Device": "GPS1",
    "SquadName": "Adrigole GPS",
    "SessionDate": "2023-04-08",
    "SessionName": "BALLINORA-HOME",
    "Drill": "Total",
    "TotalDistance": 7388,
    "HSR": 526,
    "DistPerMin": 106,
    "MaxSpeed": 8.44,
    "HighIntensityDistance": 1508,
    "NoSprints": 23,
    "SprintDistance": 441,
    "Accelerations": 33,
    "Decelerations": 51,
    "Calories": 966,
    "HSRPerMin": 8,
    "HIDPerMin": 22,
    "SprintDistancePerMin": 6,
    "StepBalanceL": 49,
    "StepBalanceR": 51,
    "DSL": 247,
    "MaxHR": 80,
    "AvgHR": 80,
    "TimeInRedZone": "0"
  },
  {
    "PlayerId": 13,
    "PlayerInitials": "CMG",
    "PlayerName": "Cathal Mike Gerry",
    "Device": "GPS1",
    "SquadName": "Adrigole GPS",
    "SessionDate": "2023-04-08",
    "SessionName": "BALLINORA-HOME",
    "Drill": "First Half",
    "TotalDistance": 3792,
    "HSR": 379,
    "DistPerMin": 108,
    "MaxSpeed": 7.61,
    "HighIntensityDistance": 799,
    "NoSprints": 17,
    "SprintDistance": 326,
    "Accelerations": 18,
    "Decelerations": 31,
    "Calories": 499,
    "HSRPerMin": 11,
    "HIDPerMin": 23,
    "SprintDistancePerMin": 9,
    "StepBalanceL": 49,
    "StepBalanceR": 51,
    "DSL": 128,
    "MaxHR": 80,
    "AvgHR": 80,
    "TimeInRedZone": "0"
  },
  {
    "PlayerId": 13,
    "PlayerInitials": "CMG",
    "PlayerName": "Cathal Mike Gerry",
    "Device": "GPS1",
    "SquadName": "Adrigole GPS",
    "SessionDate": "2023-04-08",
    "SessionName": "BALLINORA-HOME",
    "Drill": "Second Half",
    "TotalDistance": 3596,
    "HSR": 147,
    "DistPerMin": 103,
    "MaxSpeed": 8.44,
    "HighIntensityDistance": 709,
    "NoSprints": 6,
    "SprintDistance": 115,
    "Accelerations": 15,
    "Decelerations": 20,
    "Calories": 467,
    "HSRPerMin": 4,
    "HIDPerMin": 20,
    "SprintDistancePerMin": 3,
    "StepBalanceL": 49,
    "StepBalanceR": 51,
    "DSL": 119,
    "MaxHR": 80,
    "AvgHR": 80,
    "TimeInRedZone": "0"
  },
  {
    "PlayerId": 14,
    "PlayerInitials": "NOS",
    "PlayerName": "Neil O'Sullivan",
    "Device": "GPS2",
    "SquadName": "Adrigole GPS",
    "SessionDate": "2023-04-08",
    "SessionName": "BALLINORA-HOME",
    "Drill": "Total",
    "TotalDistance": 7076,
    "HSR": 325,
    "DistPerMin": 101,
    "MaxSpeed": 7.56,
    "HighIntensityDistance": 1292,
    "NoSprints": 12,
    "SprintDistance": 236,
    "Accelerations": 27,
    "Decelerations": 35,
    "Calories": 902,
    "HSRPerMin": 5,
    "HIDPerMin": 18,
    "SprintDistancePerMin": 3,
    "StepBalanceL": 49,
    "StepBalanceR": 51,
    "DSL": 296,
    "MaxHR": 0,
    "AvgHR": 0,
    "TimeInRedZone": "0"
  },
  {
    "PlayerId": 14,
    "PlayerInitials": "NOS",
    "PlayerName": "Neil O'Sullivan",
    "Device": "GPS2",
    "SquadName": "Adrigole GPS",
    "SessionDate": "2023-04-08",
    "SessionName": "BALLINORA-HOME",
    "Drill": "First Half",
    "TotalDistance": 3404,
    "HSR": 183,
    "DistPerMin": 97,
    "MaxSpeed": 7.13,
    "HighIntensityDistance": 611,
    "NoSprints": 7,
    "SprintDistance": 143,
    "Accelerations": 16,
    "Decelerations": 14,
    "Calories": 438,
    "HSRPerMin": 5,
    "HIDPerMin": 17,
    "SprintDistancePerMin": 4,
    "StepBalanceL": 49,
    "StepBalanceR": 51,
    "DSL": 140,
    "MaxHR": 0,
    "AvgHR": 0,
    "TimeInRedZone": "0"
  },
  {
    "PlayerId": 14,
    "PlayerInitials": "NOS",
    "PlayerName": "Neil O'Sullivan",
    "Device": "GPS2",
    "SquadName": "Adrigole GPS",
    "SessionDate": "2023-04-08",
    "SessionName": "BALLINORA-HOME",
    "Drill": "Second Half",
    "TotalDistance": 3672,
    "HSR": 143,
    "DistPerMin": 105,
    "MaxSpeed": 7.56,
    "HighIntensityDistance": 682,
    "NoSprints": 5,
    "SprintDistance": 93,
    "Accelerations": 11,
    "Decelerations": 21,
    "Calories": 463,
    "HSRPerMin": 4,
    "HIDPerMin": 19,
    "SprintDistancePerMin": 3,
    "StepBalanceL": 49,
    "StepBalanceR": 51,
    "DSL": 156,
    "MaxHR": 0,
    "AvgHR": 0,
    "TimeInRedZone": "0"
  },
  {
    "PlayerId": 11,
    "PlayerInitials": "SOS",
    "PlayerName": "Seanie O'Sullivan",
    "Device": "GPS5",
    "SquadName": "Adrigole GPS",
    "SessionDate": "2023-04-08",
    "SessionName": "BALLINORA-HOME",
    "Drill": "Total",
    "TotalDistance": 6710,
    "HSR": 613,
    "DistPerMin": 96,
    "MaxSpeed": 7.98,
    "HighIntensityDistance": 1476,
    "NoSprints": 19,
    "SprintDistance": 397,
    "Accelerations": 37,
    "Decelerations": 38,
    "Calories": 871,
    "HSRPerMin": 9,
    "HIDPerMin": 21,
    "SprintDistancePerMin": 6,
    "StepBalanceL": 48,
    "StepBalanceR": 52,
    "DSL": 345,
    "MaxHR": 0,
    "AvgHR": 0,
    "TimeInRedZone": "0"
  },
  {
    "PlayerId": 11,
    "PlayerInitials": "SOS",
    "PlayerName": "Seanie O'Sullivan",
    "Device": "GPS5",
    "SquadName": "Adrigole GPS",
    "SessionDate": "2023-04-08",
    "SessionName": "BALLINORA-HOME",
    "Drill": "First Half",
    "TotalDistance": 3317,
    "HSR": 302,
    "DistPerMin": 95,
    "MaxSpeed": 7.74,
    "HighIntensityDistance": 696,
    "NoSprints": 10,
    "SprintDistance": 240,
    "Accelerations": 20,
    "Decelerations": 19,
    "Calories": 431,
    "HSRPerMin": 9,
    "HIDPerMin": 20,
    "SprintDistancePerMin": 7,
    "StepBalanceL": 48,
    "StepBalanceR": 52,
    "DSL": 156,
    "MaxHR": 0,
    "AvgHR": 0,
    "TimeInRedZone": "0"
  },
  {
    "PlayerId": 11,
    "PlayerInitials": "SOS",
    "PlayerName": "Seanie O'Sullivan",
    "Device": "GPS5",
    "SquadName": "Adrigole GPS",
    "SessionDate": "2023-04-08",
    "SessionName": "BALLINORA-HOME",
    "Drill": "Second Half",
    "TotalDistance": 3393,
    "HSR": 310,
    "DistPerMin": 97,
    "MaxSpeed": 7.98,
    "HighIntensityDistance": 780,
    "NoSprints": 9,
    "SprintDistance": 157,
    "Accelerations": 17,
    "Decelerations": 19,
    "Calories": 440,
    "HSRPerMin": 9,
    "HIDPerMin": 22,
    "SprintDistancePerMin": 4,
    "StepBalanceL": 48,
    "StepBalanceR": 52,
    "DSL": 189,
    "MaxHR": 0,
    "AvgHR": 0,
    "TimeInRedZone": "0"
  },
  {
    "PlayerId": 2,
    "PlayerInitials": "CianS",
    "PlayerName": "Cian O'Shea",
    "Device": "GPS6",
    "SquadName": "Adrigole GPS",
    "SessionDate": "2023-04-08",
    "SessionName": "BALLINORA-HOME",
    "Drill": "Total",
    "TotalDistance": 6773,
    "HSR": 662,
    "DistPerMin": 97,
    "MaxSpeed": 8.15,
    "HighIntensityDistance": 1293,
    "NoSprints": 23,
    "SprintDistance": 552,
    "Accelerations": 38,
    "Decelerations": 36,
    "Calories": 872,
    "HSRPerMin": 9,
    "HIDPerMin": 18,
    "SprintDistancePerMin": 8,
    "StepBalanceL": 50,
    "StepBalanceR": 50,
    "DSL": 169,
    "MaxHR": 0,
    "AvgHR": 0,
    "TimeInRedZone": "0"
  },
  {
    "PlayerId": 2,
    "PlayerInitials": "CianS",
    "PlayerName": "Cian O'Shea",
    "Device": "GPS6",
    "SquadName": "Adrigole GPS",
    "SessionDate": "2023-04-08",
    "SessionName": "BALLINORA-HOME",
    "Drill": "First Half",
    "TotalDistance": 3488,
    "HSR": 320,
    "DistPerMin": 100,
    "MaxSpeed": 7.97,
    "HighIntensityDistance": 676,
    "NoSprints": 12,
    "SprintDistance": 262,
    "Accelerations": 20,
    "Decelerations": 16,
    "Calories": 448,
    "HSRPerMin": 9,
    "HIDPerMin": 19,
    "SprintDistancePerMin": 7,
    "StepBalanceL": 50,
    "StepBalanceR": 50,
    "DSL": 84,
    "MaxHR": 0,
    "AvgHR": 0,
    "TimeInRedZone": "0"
  },
  {
    "PlayerId": 2,
    "PlayerInitials": "CianS",
    "PlayerName": "Cian O'Shea",
    "Device": "GPS6",
    "SquadName": "Adrigole GPS",
    "SessionDate": "2023-04-08",
    "SessionName": "BALLINORA-HOME",
    "Drill": "Second Half",
    "TotalDistance": 3285,
    "HSR": 341,
    "DistPerMin": 94,
    "MaxSpeed": 8.15,
    "HighIntensityDistance": 617,
    "NoSprints": 11,
    "SprintDistance": 291,
    "Accelerations": 18,
    "Decelerations": 20,
    "Calories": 424,
    "HSRPerMin": 10,
    "HIDPerMin": 18,
    "SprintDistancePerMin": 8,
    "StepBalanceL": 50,
    "StepBalanceR": 50,
    "DSL": 85,
    "MaxHR": 0,
    "AvgHR": 0,
    "TimeInRedZone": "0"
  },
  {
    "PlayerId": 15,
    "PlayerInitials": "DIOS",
    "PlayerName": "Diarmud O'Sullivan",
    "Device": "GPS4",
    "SquadName": "Adrigole GPS",
    "SessionDate": "2023-04-08",
    "SessionName": "BALLINORA-HOME",
    "Drill": "Total",
    "TotalDistance": 6408,
    "HSR": 376,
    "DistPerMin": 92,
    "MaxSpeed": 7.43,
    "HighIntensityDistance": 1032,
    "NoSprints": 12,
    "SprintDistance": 262,
    "Accelerations": 20,
    "Decelerations": 30,
    "Calories": 806,
    "HSRPerMin": 5,
    "HIDPerMin": 15,
    "SprintDistancePerMin": 4,
    "StepBalanceL": 50,
    "StepBalanceR": 50,
    "DSL": 284,
    "MaxHR": 0,
    "AvgHR": 0,
    "TimeInRedZone": "0"
  },
  {
    "PlayerId": 15,
    "PlayerInitials": "DIOS",
    "PlayerName": "Diarmud O'Sullivan",
    "Device": "GPS4",
    "SquadName": "Adrigole GPS",
    "SessionDate": "2023-04-08",
    "SessionName": "BALLINORA-HOME",
    "Drill": "First Half",
    "TotalDistance": 3513,
    "HSR": 237,
    "DistPerMin": 100,
    "MaxSpeed": 7.43,
    "HighIntensityDistance": 617,
    "NoSprints": 8,
    "SprintDistance": 175,
    "Accelerations": 16,
    "Decelerations": 17,
    "Calories": 448,
    "HSRPerMin": 7,
    "HIDPerMin": 18,
    "SprintDistancePerMin": 5,
    "StepBalanceL": 50,
    "StepBalanceR": 50,
    "DSL": 162,
    "MaxHR": 0,
    "AvgHR": 0,
    "TimeInRedZone": "0"
  },
  {
    "PlayerId": 15,
    "PlayerInitials": "DIOS",
    "PlayerName": "Diarmud O'Sullivan",
    "Device": "GPS4",
    "SquadName": "Adrigole GPS",
    "SessionDate": "2023-04-08",
    "SessionName": "BALLINORA-HOME",
    "Drill": "Second Half",
    "TotalDistance": 2895,
    "HSR": 139,
    "DistPerMin": 83,
    "MaxSpeed": 7.28,
    "HighIntensityDistance": 415,
    "NoSprints": 4,
    "SprintDistance": 87,
    "Accelerations": 4,
    "Decelerations": 13,
    "Calories": 358,
    "HSRPerMin": 4,
    "HIDPerMin": 12,
    "SprintDistancePerMin": 2,
    "StepBalanceL": 50,
    "StepBalanceR": 50,
    "DSL": 122,
    "MaxHR": 0,
    "AvgHR": 0,
    "TimeInRedZone": "0"
  },
  {
    "PlayerId": 6,
    "PlayerInitials": "DH",
    "PlayerName": "David Harrington",
    "Device": "GPS3",
    "SquadName": "Adrigole GPS",
    "SessionDate": "2023-04-08",
    "SessionName": "BALLINORA-HOME",
    "Drill": "Total",
    "TotalDistance": 5336,
    "HSR": 488,
    "DistPerMin": 76,
    "MaxSpeed": 8.96,
    "HighIntensityDistance": 1036,
    "NoSprints": 19,
    "SprintDistance": 379,
    "Accelerations": 39,
    "Decelerations": 32,
    "Calories": 697,
    "HSRPerMin": 7,
    "HIDPerMin": 15,
    "SprintDistancePerMin": 5,
    "StepBalanceL": 49,
    "StepBalanceR": 51,
    "DSL": 246,
    "MaxHR": 0,
    "AvgHR": 0,
    "TimeInRedZone": "0"
  },
  {
    "PlayerId": 6,
    "PlayerInitials": "DH",
    "PlayerName": "David Harrington",
    "Device": "GPS3",
    "SquadName": "Adrigole GPS",
    "SessionDate": "2023-04-08",
    "SessionName": "BALLINORA-HOME",
    "Drill": "First Half",
    "TotalDistance": 2899,
    "HSR": 329,
    "DistPerMin": 83,
    "MaxSpeed": 8.96,
    "HighIntensityDistance": 601,
    "NoSprints": 12,
    "SprintDistance": 270,
    "Accelerations": 23,
    "Decelerations": 16,
    "Calories": 383,
    "HSRPerMin": 9,
    "HIDPerMin": 17,
    "SprintDistancePerMin": 8,
    "StepBalanceL": 49,
    "StepBalanceR": 51,
    "DSL": 141,
    "MaxHR": 0,
    "AvgHR": 0,
    "TimeInRedZone": "0"
  },
  {
    "PlayerId": 6,
    "PlayerInitials": "DH",
    "PlayerName": "David Harrington",
    "Device": "GPS3",
    "SquadName": "Adrigole GPS",
    "SessionDate": "2023-04-08",
    "SessionName": "BALLINORA-HOME",
    "Drill": "Second Half",
    "TotalDistance": 2436,
    "HSR": 158,
    "DistPerMin": 70,
    "MaxSpeed": 7.72,
    "HighIntensityDistance": 435,
    "NoSprints": 7,
    "SprintDistance": 109,
    "Accelerations": 16,
    "Decelerations": 16,
    "Calories": 314,
    "HSRPerMin": 5,
    "HIDPerMin": 12,
    "SprintDistancePerMin": 3,
    "StepBalanceL": 49,
    "StepBalanceR": 51,
    "DSL": 105,
    "MaxHR": 0,
    "AvgHR": 0,
    "TimeInRedZone": "0"
  }
]


