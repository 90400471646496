import { BrowserRouter, Routes, Route, } from "react-router-dom";
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import TagHome from "./TagHome";
import TagTop from "./TagTop";
import ChartHome from "./ChartHome";
import Home from "./Home";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            //other query settings
            refetchOnWindowFocus: false,
        },
    },
});

export default function Index(props) {
    return (
        <QueryClientProvider client={queryClient}>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/chart" element={<ChartHome />} />
                    <Route path="/tag/:session/:source/:userKey" element={<TagHome />} />
                    <Route path="/tag" element={<TagTop />} />
                </Routes>
            </BrowserRouter>
            <ReactQueryDevtools />
        </QueryClientProvider>

    );
}


