import Container from '@mui/material/Container';
import { useState, useRef } from "react";
import { useQuery, useMutation, useQueryClient } from 'react-query';
import CssBaseline from '@mui/material/CssBaseline';
import * as api from '../api/gaastats-api';
import TagScroll from '../components/TagScroll';
import TagEntry from '../components/TagEntry';
import { useParams } from 'react-router-dom';




export default function TagHome() {
    const { session, source, userKey } = useParams();


    const [tags, setTags] = useState([]);
    const [updateDatabase, setUpdateDatabase] = useState(true);
    const topOfEntry = useRef(null);

    const { data: tagList, isLoading, isError, } =
        useQuery(["taglist",session,source,userKey], () => api.getRawtag(session, source, userKey));

    const queryClient = useQueryClient();
    const mutation = useMutation(({id, updateTags}) => {
        console.log(id);
        console.log(JSON.stringify(updateTags))
        return api.updateRawtag(id, updateTags);
    }, {
        onSuccess: () => {

            // Invalidate
            queryClient.invalidateQueries('taglist');
        },
    });


    if (isLoading) {
        return "Loading..."
    }

    if (isError) {
        return "Error fetching tags"
    }

    const tagTmp =tagList.length?JSON.parse(tagList[0].tagDataJson):[];

    console.log(JSON.stringify(tagTmp));

    if (tagTmp.length > tags.length) {
        setTags(tagTmp);
    }

    const updateRawtag =  (id, updateTags) => {
        mutation.mutate({id, updateTags});
    }
    
    
    if (tags.length > tagTmp.length && updateDatabase) {
        const updateTags = { ...tagList[0], tagDataJson: `${JSON.stringify(tags)}` };
        updateRawtag(tagList[0].id, updateTags);
        setUpdateDatabase(false);
    }


    return (
        <Container component="main" maxWidth="sm" sx={{ height: 300, }}>
            <CssBaseline />
            <TagEntry tags={tags} setTags={setTags} topOfEntry={topOfEntry} />
            <TagScroll tags={tags} topOfEntry={topOfEntry} setUpdateDatabase={setUpdateDatabase}/>
        </Container>
    );
}

