import { useRef, useState, useEffect } from "react";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import * as api from '../api/gaastats-api';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


export default function SessionSelectMulti({sessions, setSessions, setPlayerIds }) {

    const [sessionList, setSessionList] = useState([]);
    const [sessionNames, setSessionNames] = useState(sessions);

    useEffect(() => {
        async function fetchData() {
            const res = await api.getSessions();
            setSessionList(res);
            console.log(JSON.stringify(sessionList));
        }
        fetchData();
    }, []);



    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setSessionNames(
            typeof value === 'string' ? value.split(',') : value);
    };

    const handleClose = (event) => {
        setSessions(sessionNames);
    };

    if (sessionList.length === 0) {
        return 'Loading sessions..'
    }
    return (
        <div>
            <FormControl sx={{ m: 1, width: 300 }}>
                <InputLabel id="demo-multiple-checkbox-label">Sessions</InputLabel>
                <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={sessionNames}
                    onChange={handleChange}
                    onClose={handleClose}
                    input={<OutlinedInput label="Sessions" />}
                    renderValue={(selected) => selected.join(', ')}
                    MenuProps={MenuProps}
                >
                    {sessionList.map((name) => (
                        <MenuItem key={name.SessionName} value={name.SessionName}>
                            <Checkbox checked={sessionNames.indexOf(name.SessionName) > -1} />
                            <ListItemText primary={name.SessionName} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}