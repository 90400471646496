import { useState, useRef } from "react";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


export default function PlayerSelect({playerList, playerIds, setPlayerIds}) {
    const playerNames = useRef([]);

    if (playerIds.length==0) {
        playerNames.current=[]
    }
    
    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        playerNames.current=
            typeof value === 'string' ? value.split(',') : value;
        
        const newIds = playerList.filter((name) => value.indexOf(name.PlayerName) > -1);
        const pp=newIds.map((name) => name.PlayerId);
        setPlayerIds(pp);
    };

    return (
        <div>
            <FormControl sx={{ m: 1, width: 300 }}>
                <InputLabel id="demo-multiple-checkbox-label">Players</InputLabel>
                <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={playerNames.current}
                    onChange={handleChange}
                    input={<OutlinedInput label="Players" />}
                    renderValue={(selected) => selected.join(', ')}
                    MenuProps={MenuProps}
                >
                    {playerList.map((name) => (
                        <MenuItem key={name.PlayerId} value={name.PlayerName}>
                            <Checkbox checked={playerNames.current.indexOf(name.PlayerName) > -1} />
                            <ListItemText primary={name.PlayerName} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}