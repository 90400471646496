import Box from '@mui/material/Box';
import { useQuery } from 'react-query';
import * as api from '../api/gaastats-api';
import { DataGrid } from '@mui/x-data-grid';


export default function StatList({ chartParams }) {

    console.log(JSON.stringify(chartParams));

    const { data: listData, isLoading: isListLoading, isError: isListError } =
        useQuery(["data", chartParams], () => api.getData(chartParams.sessions, chartParams.players.toString()));

    if (isListLoading) {
        return "List loading...";
    }

    if (isListError) {
        return "List load error...";
    }

    const isMatch = () => {
        var res = false;
        res = chartParams.sessions.toString().includes('HOME')||chartParams.sessions.toString().includes('AWAY');
        return res;
    }


    const isMulti = () => {
        var res = false;
        res = chartParams.sessions.length>1;
        return res;
    }

    var visibleColumns;
    switch (chartParams.chart) {
        case "List-Volume":
            visibleColumns = {...columnsVolume,SessionName:isMulti(),Drill:isMatch(),}
            break;
        case "List-Speed":
            visibleColumns = {...columnsSpeed,SessionName:isMulti(),Drill:isMatch(),}
            break;
        case "List-Intensity":
            visibleColumns = {...columnsIntensity,SessionName:isMulti(),Drill:isMatch(),}
            break;
        case "List-Stress":
            visibleColumns = {...columnsStress,SessionName:isMulti(),Drill:isMatch(),};
            break;
        default:
            visibleColumns = {...columnsMinimum,SessionName:isMulti(),Drill:isMatch(),};
            break;
    }

    return (
        <Box sx={{ margin: 2, height: 400, width: '100%', overflow: 'auto' }}>
            <DataGrid
                rows={listData}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 20,
                        },
                    },
                    columns: {
                        columnVisibilityModel: visibleColumns,
                    },
                }}
                pageSizeOptions={[20]}
                disableRowSelectionOnClick
            />
        </Box>
    );
}


const columnsMinimum = {
    // Hide columns
    PlayerName: true,
    PlayerInitials: false,
    SessionName: false,
    Drill: true,
    TotalDistance: false,
    HSR: false,
    DistPerMin: false,
    MaxSpeed: false,
    HighIntensityDistance: false,
    NoSprints: false,
    SprintDistance: false,
    Accelerations: false,
    Decelerations: false,
    Calories: false,
    HSRPerMin: false,
    HIDPerMin: false,
    SprintDistancePerMin: false,
    StepBalanceL: false,
    StepBalanceR: false,
    DSL: false,
    MaxHR: false,
    AvgHR: false,
    TimeInRedZone: false,
    Minutes: false,
}
const columnsVolume = {
    // Hide columns
    PlayerName: true,
    PlayerInitials: false,
    SessionName: false,
    Drill: true,
    TotalDistance: true,
    HSR: true,
    DistPerMin: false,
    MaxSpeed: false,
    HighIntensityDistance: true,
    NoSprints: false,
    SprintDistance: true,
    Accelerations: false,
    Decelerations: false,
    Calories: false,
    HSRPerMin: false,
    HIDPerMin: false,
    SprintDistancePerMin: false,
    StepBalanceL: false,
    StepBalanceR: false,
    DSL: false,
    MaxHR: false,
    AvgHR: false,
    TimeInRedZone: false,
    Minutes: false,
};
const columnsSpeed = {
    // Hide columns
    PlayerName: true,
    PlayerInitials: false,
    SessionName: false,
    Drill: true,
    TotalDistance: false,
    HSR: false,
    DistPerMin: false,
    MaxSpeed: true,
    HighIntensityDistance: false,
    NoSprints: true,
    SprintDistance: false,
    Accelerations: true,
    Decelerations: true,
    Calories: false,
    HSRPerMin: false,
    HIDPerMin: false,
    SprintDistancePerMin: false,
    StepBalanceL: false,
    StepBalanceR: false,
    DSL: false,
    MaxHR: false,
    AvgHR: false,
    TimeInRedZone: false,
    Minutes: false,
};
const columnsIntensity = {
    // Hide columns
    PlayerName: true,
    PlayerInitials: false,
    SessionName: false,
    Drill: true,
    TotalDistance: false,
    HSR: false,
    DistPerMin: true,
    MaxSpeed: false,
    HighIntensityDistance: false,
    NoSprints: false,
    SprintDistance: false,
    Accelerations: false,
    Decelerations: false,
    Calories: false,
    HSRPerMin: true,
    HIDPerMin: true,
    SprintDistancePerMin: true,
    StepBalanceL: false,
    StepBalanceR: false,
    DSL: false,
    MaxHR: false,
    AvgHR: false,
    TimeInRedZone: false,
    Minutes: false,
};
const columnsStress = {
    // Hide columns
    PlayerName: true,
    PlayerInitials: false,
    SessionName: false,
    Drill: true,
    TotalDistance: true,
    HSR: false,
    DistPerMin: false,
    MaxSpeed: false,
    HighIntensityDistance: false,
    NoSprints: false,
    SprintDistance: false,
    Accelerations: false,
    Decelerations: false,
    Calories: false,
    HSRPerMin: false,
    HIDPerMin: false,
    SprintDistancePerMin: false,
    StepBalanceL: true,
    StepBalanceR: true,
    DSL: true,
    MaxHR: false,
    AvgHR: false,
    TimeInRedZone: false,
    Minutes: false,
};



const columns = [
    {
        field: 'PlayerName',
        headerName: 'Name',
        width: 150,
        description: 'The players full name.',
    },
    {
        field: 'PlayerInitials',
        headerName: 'Inits',
        width: 80,
        description: 'The players initials.',
    },
    {
        field: 'SessionName',
        headerName: 'Session',
        width: 150,
        description: 'The name of the training session or match.',
    },
    {
        field: 'Drill',
        headerName: 'Drill',
        width: 100,
        description: 'Drill down level.  Matches have Total, First Half, Second half.  Training is Training.',
    },
    {
        field: 'TotalDistance',
        headerName: 'Total',
        type: 'number',
        width: 80,
        description: 'Total distance in session.',
        disableColumnMenu: true,
    },
    {
        field: 'HSR',
        headerName: 'HSR ',
        type: 'number',
        width: 80,
        description: 'High Speed Running distance.',
    },
    {
        field: 'DistPerMin',
        headerName: 'Dis/Mn',
        type: 'number',
        width: 80,
        description: 'Total distance divided by duration of session in minutes.',
    },
    {
        field: 'MaxSpeed',
        headerName: 'Speed',
        type: 'number',
        width: 80,
        description: 'Maximum speed.',
    },
    {
        field: 'HighIntensityDistance',
        headerName: 'HID',
        type: 'number',
        width: 80,
        description: 'HSR plus distance covered during accleration and deceleration.',
    },
    {
        field: 'NoSprints',
        headerName: '#Sprints',
        type: 'number',
        width: 80,
        description: 'No of sprints.',
    },
    {
        field: 'SprintDistance',
        headerName: 'Spnt Dst',
        type: 'number',
        width: 80,
        description: 'Sprinting distance.',
    },
    {
        field: 'Accelerations',
        headerName: '#Accel',
        type: 'number',
        width: 80,
        description: 'Number of accelerations.',
    },
    {
        field: 'Decelerations',
        headerName: '#Decel',
        type: 'number',
        width: 80,
        description: 'Number of decelerations.',
    },
    {
        field: 'Calories',
        headerName: 'Calories',
        type: 'number',
        width: 80,
    },
    {
        field: 'HSRPerMin',
        headerName: 'HSR/mn',
        type: 'number',
        width: 80,
        description: 'HSR divided by duration of the session in minutes.',
    },
    {
        field: 'HIDPerMin',
        headerName: 'HID/mn',
        type: 'number',
        width: 80,
        description: 'HID divided by the duration of the session in minutes.',
    },
    {
        field: 'SprintDistancePerMin',
        headerName: 'SD/mn',
        type: 'number',
        width: 75,
        description: 'Sprint distance divided by the duration of the session in minutes.',
    },
    {
        field: 'StepBalanceL',
        headerName: 'StepL',
        type: 'number',
        width: 80,
        description: 'Step balance left.',
    },
    {
        field: 'StepBalanceR',
        headerName: 'StepR',
        type: 'number',
        width: 80,
        description: 'Step balance right.',
    },
    {
        field: 'DSL',
        headerName: 'DSL',
        type: 'number',
        width: 80,
        description: 'Dynamic Stress Load.',
    },
    {
        field: 'MaxHR',
        headerName: 'MaxHR',
        type: 'number',
        width: 80,
        description: 'Maximum Heart Rate.  Not recording.',
    },
    {
        field: 'AvgHR',
        headerName: 'AvgHR',
        type: 'number',
        width: 80,
        description: 'Average Heart Rate.  Not recording.',
    },
    {
        field: 'TimeInRedZone',
        headerName: 'TimeRZ',
        width: 80,
        description: 'Time in Red Zone.  Dependent on Heart Rate.',
    },
    {
        field: 'Minutes',
        headerName: 'OvrMins',
        type: 'number',
        width: 80,
        description: 'Minutes overide.  Present if length of individuals session added manually.  Reflects in per min stats.',
    },
];