import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { useState } from "react";
import CssBaseline from '@mui/material/CssBaseline';
import SessionSelect from '../components/SessionSelect';
import SessionSelectMulti from '../components/SessionSelectMulti';
import ChartSelect from '../components/ChartSelect';
import PlayerSelect from '../components/PlayerSelect';
import * as api from '../api/gaastats-api';
import { useQuery } from 'react-query';
import ChartBox from '../components/ChartBox';
import AllOnlyToggle from '../components/AllOnlyToggle';

export default function ChartHome() {

    const [sessions, setSessions] = useState([]);
    const [chart, setChart] = useState('');
    const [playerIds, setPlayerIds] = useState([]);
    const [allOrOnly, setAllOrOnly] = useState('ALL');

    const { data: playerList, isLoading: isPlayersLoading, isError: isPlayersError, isFetched: isPlayersFetched } =
        useQuery(["players", [sessions, allOrOnly]], () => api.getPlayers(sessions,allOrOnly));
    //const { data: sessionList, isLoading: isSessionsLoading, isError: isSessionsError, isFetched: isSessionsFetched } = useQuery("sessions", api.getSessions);

    if (isPlayersLoading) {
        return (`Loading data: players: ${isPlayersLoading} `);
    }

    if (isPlayersError) {
        return ('Error loading data.');
    }

    console.log(`isPlayersFetched: ${isPlayersFetched}`)


    return (
        <Container component="main" maxWidth="sm">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <AllOnlyToggle allOrOnly={allOrOnly} setAllOrOnly={setAllOrOnly} setPlayerIds={setPlayerIds} />
                <SessionSelectMulti sessions={sessions} setSessions={setSessions} setPlayerIds={setPlayerIds} />
                <PlayerSelect playerList={playerList} playerIds={playerIds} setPlayerIds={setPlayerIds} />
                <ChartSelect chart={chart} setChart={setChart} />
            </Box>
            <ChartBox chart={chart} sessions={sessions} players={playerIds} />
        </Container>
    );
}

