import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useRef, useState, } from 'react';

export default function TagEntry({ tags, setTags, topOfEntry }) {


    const [entry, setEntry] = useState({ entry: "", time: "" });
    const [help, setHelp] = useState({ entryLevel: '', detail: '', isError: false, errorText: '' });
    const inputRef = useRef();
    const lastTime = useRef('00:00');



    const handleSubmit = (event) => {
        event.preventDefault();

        const newTagArray = [...tags];
        const formData = new FormData(event.currentTarget);
        const newTag = {
            raw: formData.get('tag-raw'),
            time: getManualTime(formData.get('tag-time'), lastTime),
        };

        if (newTag.raw === "-") {
            newTagArray.pop();
            setEntry({ entry: "", time: "", });
            setTags(newTagArray);
            setHelp({ entryLevel: '', detail: getEntryLevels(), isError: false, errorText: '' });
            return
        }

        const validationObj = validateEntry(newTag.raw);
        if (validationObj.valid) {
            const currentdate = new Date();
            const datestamp = currentdate.getFullYear() + "-"
                + (currentdate.getMonth() + 1).toString().padStart(2, "0") + "-"
                + currentdate.getDate().toString().padStart(2, "0")
            const timestamp = currentdate.getHours().toString().padStart(2, "0") + ":"
                + currentdate.getMinutes().toString().padStart(2, "0") + ":"
                + currentdate.getSeconds().toString().padStart(2, "0");
            const csv = newTag.raw.split('.')[0].split('').toString();
            const validTag = {
                ...newTag,
                entryLevel: validationObj.entryLevel,
                csv: csv,
                validationObj: validationObj,
                numberQualifier: getNumberQualifier(newTag.raw),
                primaryPlayer: getPlayer(newTag.raw, 1),
                secondaryPlayer: getPlayer(newTag.raw, 2),
                remainder: getRemainder(newTag.raw),
                datestamp: datestamp,
                timestamp: timestamp
            }
            newTagArray.push(validTag);
            setEntry({ entry: "", time: "", });
            setTags(newTagArray);
            setHelp({ entryLevel: '', detail: getEntryLevels(), isError: false, errorText: '' });
            inputRef.current.focus();
        } else {
            setEntry({ entry: "", time: "" });
            setHelp({ entryLevel: '', detail: getEntryLevels(), isError: true, errorText: validationObj.text });
        }

    }

    const updateActivityHelp = (activity, index) => {
        const val1 = tagSchema.entryIds.indexOf(activity);
        if (val1 > -1) {
            const entryLevel = tagSchema.entryNames[val1];
            if (tagSchema[entryLevel].fieldOptions[index]) {
                const choices = tagSchema[entryLevel].fieldOptions[index].valid.map((opt, ind) => {
                    const choice = opt + "=" + tagSchema[entryLevel].fieldOptions[index].desc[ind];
                    return choice
                })
                const activityHelp = `${tagSchema[entryLevel].fieldName[index]}: ${choices.join('/')} `;
                setHelp({ ...help, entryLevel: entryLevel, detail: activityHelp === undefined ? '' : activityHelp });
            } else {
                setHelp({ ...help, entryLevel: entryLevel, detail: '"." for players' });
            }
        } else {
            setHelp({ ...help, entryLevel: '', detail: getEntryLevels() });
        }
    }

    // eslint-disable-next-line
    const validateChar = (activity, index) => {

    }

    const handleChange = (event) => {
        setEntry({ ...entry, entry: event.target.value });
        const activity = event.target.value.charAt(0);
        updateActivityHelp(activity, event.target.value.length - 1);
    }

    const handleTimeChange = (event) => {
        setEntry({ ...entry, time: event.target.value });
    }

    const getEntryLevels = () => {
        const levelDesc = tagSchema.entryIds.map((id, index) => {
            return id + "=" + tagSchema.entryNames[index];
        })
        return levelDesc.join(' | ');
    }

    return (
        <Box>
            < Typography ref={topOfEntry} > GAA Taging</Typography >
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',

            }}
            >
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '10ch' },
                        display: 'flex',
                        flexDirection: 'row',
                    }}
                    noValidate
                    autoComplete="off"
                    onSubmit={handleSubmit}
                >
                    <TextField
                        error={help.isError}
                        id="tag-raw"
                        name="tag-raw"
                        label="Enter Tag"
                        value={entry.entry}
                        onChange={handleChange}
                        helperText={help.errorText}
                        inputProps={{ inputMode: 'numeric' }}
                        inputRef={inputRef}
                    />
                    <TextField
                        id="tag-time"
                        name="tag-time"
                        label="Time"
                        value={entry.time}
                        onChange={handleTimeChange}
                        inputProps={{ inputMode: 'numeric' }}
                    />
                    <Button type='submit' sx={{ display: 'none' }}>Submit</Button>
                </Box>
                <TextField
                    id="tag-help"
                    name="tag-help"
                    label={help.entryLevel}
                    value={help.detail}
                    InputProps={{
                        readOnly: true,
                    }}
                    sx={{ m: 1, fontSize: 10 }}
                    fullWidth
                    multiline
                />
            </Box>
        </Box>
    );
}

const getPlayer = (tagIn, player) => {
    // Player must be 1 or 2

    const playerOut = tagIn.split('.')[player];
    return playerOut ? playerOut : '';
}

const getManualTime = (timeIn, lastTime) => {

    const isNum = /^\d+$/.test(timeIn);
    if (!isNum) {
        return lastTime.current + ":01";
    }

    var timeOut;
    switch (timeIn.length) {
        case 4:
            timeOut = timeIn.substr(0, 2) + ":" + timeIn.substr(2, 2);
            break;

        case 3:
            timeOut = "0" + timeIn.substr(0, 1) + ":" + timeIn.substr(1, 2);
            break;

        default:
            timeOut = "";
            break;

    }
    lastTime.current = timeOut;
    return timeOut;
}

const getNumberQualifier = (tagIn) => {
    const numStr = tagIn.split('+')[1]
    if (numStr === undefined) {
        return "";
    } else {
        const numStr2 = numStr.split('*')[0];
        if (/^\d+$/.test(numStr2)) {
            return numStr2;
        } else {
            return "";
        }
    }
}

const getRemainder = (tagIn) => {
    // Player must be 1 or 2

    const remainder = tagIn.split('*')[1];
    if (remainder === undefined) {
        return "";
    } else {
        return remainder;
    }
}

const validateEntry = (tagIn) => {
    var entryLevel;
    const opts = tagIn.split('.')[0].split('+')[0]; //Options are before the '.' or '+'

    const pos = opts.split('');
    const val1 = tagSchema.entryIds.indexOf(pos[0]);
    if (val1 > -1) {
        entryLevel = tagSchema.entryNames[val1];
        console.log(entryLevel);
    } else {
        console.log(val1);
        return { valid: false, text: 'Invalid:  Unknown activity' };
    }

    const levelProps = tagSchema[entryLevel];
    console.log(`Entry Level: ${entryLevel} props: ${JSON.stringify(levelProps)}`);

    const man = pos.slice(1);
    if (man.length < tagSchema[entryLevel].mandatory) {
        console.log("Invalid");
        return { valid: false, text: 'Invalid:  Missing mandatory detail' };
    } else {
        console.log(man);
    }
    var validateDesc = `${entryLevel} -> `;
    var validateObj = { valid: true, entryLevel: `${entryLevel}` };
    var undefinedFields = 0;

    man.forEach((id, index) => {
        console.log(`id: ${id}, index:${index}`);

        if (tagSchema[entryLevel].fieldName[index] !== undefined) {
            const tst = tagSchema[entryLevel].fieldOptions[index].valid.indexOf(id);
            console.log(tst);
            if (tst > -1) {
                validateDesc = validateDesc.concat(`${tagSchema[entryLevel].fieldName[index]}: ${tagSchema[entryLevel].fieldOptions[index].desc[tst]}, `);
                validateObj = { ...validateObj, [tagSchema[entryLevel].fieldName[index]]: `${tagSchema[entryLevel].fieldOptions[index].desc[tst]}` }
            }
        } else {
            console.log('Undefined fields ignored');
            undefinedFields++;
        }

    })
    if (undefinedFields) {
        validateDesc = validateDesc.concat(`Undefined: ${undefinedFields}`);
        validateObj = { ...validateObj, undefined: undefinedFields }
    }
    console.log(JSON.stringify(validateObj));
    return { ...validateObj, text: validateDesc };
}

const tagSchema = {
    entryNames: ["Misc", "KickOut", "Possession", "Turnover", "Shot", "Foul"],
    entryIds: ["0", "1", "2", "3", "4", "5"],

    Misc: {
        mandatory: 2,
        helpLine: "",
        fieldName: ["Who", "Type",],
        fieldOptions: [{ valid: ["1", "2", "3"], desc: ["Us", "Them", "None"] },
        { valid: ["1", "2", "3",], desc: ["Throw-Ball", "Line-ball", "Note",] },]
    },


    KickOut: {
        mandatory: 4,
        helpLine: "",
        fieldName: ["Who", "Distance", "Quality", "Winner"],
        fieldOptions: [{ valid: ["1", "2"], desc: ["Us", "Them"] }, { valid: ["1", "2"], desc: ["Long", "Short"] }, { valid: ["1", "2", "3"], desc: ["Clean", "Broken", "Mark"] }, { valid: ["1", "2"], desc: ["Us", "Them"] },]
    },

    Possession: {
        mandatory: 3,
        helpLine: "",
        fieldName: ["Who", "Source", "Result"],
        fieldOptions: [{ valid: ["1", "2"], desc: ["Us", "Them"] }, { valid: ["1", "2", "3", "4"], desc: ["KickOut", "Turnover", "Free", "Restart"] },
        { valid: ["1", "2", "3", "4", "5",], desc: ["Mark", "Shot", "Foul", "Turnover", "Recycle"] },]
    },

    Turnover: {
        mandatory: 3,
        helpLine: "",
        fieldName: ["StartPossession", "EndPossession", "Method",],
        fieldOptions: [{ valid: ["1", "2"], desc: ["Us", "Them"] }, { valid: ["1", "2"], desc: ["Us", "Them"] }, { valid: ["1", "2", "3", "4", "5", "6", "7"], desc: ["Block", "Tackle", "Intercept", "Foul", "MissedShot", "Line-ball", "LoosePass"] },]
    },

    Shot: {
        mandatory: 3,
        helpLine: "",
        fieldName: ["Who", "Source", "Result"],
        fieldOptions: [{ valid: ["1", "2"], desc: ["Us", "Them"] }, { valid: ["1", "2", "3", "4", "5"], desc: ["Free", "Attack", "Turnover", "Mark", "Penalty"] }, { valid: ["1", "2", "3", "4", "5", "6"], desc: ["Wide", "Point", "Goal", "Save", "Turnover", "Recycle"] },]
    },

    Foul: {
        mandatory: 3,
        helpLine: "",
        fieldName: ["Who", "Level", "Result"],
        fieldOptions: [{ valid: ["1", "2"], desc: ["Us", "Them"] },
        { valid: ["1", "2", "3", ], desc: ["Free", "Yellow", "Red",] },
        { valid: ["1", "2", "3", "4", "5"], desc: ["Free", "Turnover", "Shot", "Penalty"] },]
    },

}


