import Container from '@mui/material/Container';
import { useState, } from "react";
import CssBaseline from '@mui/material/CssBaseline';
import * as api from '../api/gaastats-api';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';

export default function TagTop() {

    const [params, setParams] = useState({ session: '', source: '', userKey: '' });
    const navigate = useNavigate();
    const { data, isLoading, isError, } = useQuery(["session"], () => api.getRawtagSessions());
    if (isLoading) {
        return "Loading..."
    }

    if (isError) {
        return "Error loading."
    }

    function onlyUnique(value, index, array) {
        return array.indexOf(value) === index;
    }

    const handleChangeValue = (name, newValue) => {
        setParams({ ...params, [name]: newValue ? newValue : '' });
    }

    const handleChangeText = (event, value, reason) => {
        setParams({ ...params, [event.target.name]: event.target.value ? event.target.value : "" });
    }

    const handleClick = async () => {
        const inserts = await api.createRawtag({
            session: params.session,
            source: params.source,
            user: "",
            userKey: params.userKey,
            tagDataJson: "[]"
        });
        console.log(JSON.stringify(inserts));

        navigate(`/tag/${params.session}/${params.source}/${params.userKey}`);
    }

    const sessionOptions = data.map((item) => item.session).filter(onlyUnique);
    const userKeyOptions = data.filter((item) => {
        if (item.session === params.session && item.source === params.source) {
            return true;
        } else {
            return false
        }
    }).map((item) => item.userKey);

    return (
        <Container component="main" maxWidth="sm" sx={{ height: 300, }}>
            <CssBaseline />
            <Box
                noValidate
                autoComplete="off"

                sx={{
                    '& .MuiTextField-root': { m: 1, width: '15ch' },
                    display: 'flex',
                    flexDirection: 'row',
                }}
            >
                <Autocomplete
                    id="tag-session"
                    name="session"
                    label="Enter session"
                    value={params.session}
                    inputValue={params.session}
                    onInputChange={handleChangeText}
                    onChange={(event, newValue) => {
                        handleChangeValue("session", newValue);
                    }}
                    freeSolo
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    options={sessionOptions}
                    renderInput={(params) => <TextField {...params} name="session" />}
                />
                <FormControl sx={{ m: 1, width: '15ch' }}>
                    <InputLabel id="demo-simple-select-label">Stat Set</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="tag-source"
                        name="source"
                        label="Enter source"
                        value={params.source}
                        onChange={handleChangeText}
                    >
                        <MenuItem value={"video"}>video</MenuItem>
                        <MenuItem value={"live"}>live</MenuItem>
                    </Select>
                </FormControl>
                <Autocomplete
                    id="tag-userkey"
                    name="userKey"
                    label="Enter User Key"
                    inputValue={params.userKey}
                    onInputChange={handleChangeText}
                    value={params.userKey}
                    onChange={(event, newValue) => {
                        handleChangeValue("userKey", newValue);
                    }}
                    freeSolo
                    options={userKeyOptions}
                    renderInput={(params) => <TextField {...params} name="userKey" />}
                />
                <Button onClick={handleClick}>Go</Button>

            </Box>
        </Container>
    );
}

