import Box from '@mui/material/Box';
import { useRef, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button } from '@mui/material';

export default function TagScroll({ tags, topOfEntry, setUpdateDatabase }) {

    const topRef = useRef(null);

    useEffect(() => {
        // 👇️ scroll to top every time messages change
        topRef.current?.scrollIntoView({ behavior: 'smooth' });
        topOfEntry.current?.scrollIntoView({ behavior: 'smooth' });

    }, [tags,topOfEntry]);


    const handleClickUpdate = () => {
        setUpdateDatabase(true);
    }

    return (

        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Box sx={{
                m: 1, p: 1, height: 250, width: '90%', overflow: 'auto', border: 1, borderRadius: 2
            }}>

                <div ref={topRef} />
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 350, maxHeight: 250 }} aria-label="simple table">
                        <TableBody>
                            {tags.slice(0).reverse().map((tag) => (
                                <TableRow
                                    key={tag.timestamp}
                                    sx={{ '& .MuiTableRow-root:': { border: 1, fontSize: 10 } }}
                                >
                                    <TableCell width={"15%"} align="left">{tag.raw}</TableCell>
                                    <TableCell align="left" sx={{ color: "red" }}>{tag.time}</TableCell>
                                    <TableCell align="left" sx={{ color: "blue", fontSize: 8 }}>{tag.validationObj.text} {tag.numberQualifier?'#'+tag.numberQualifier:''} ({tag.primaryPlayer},{tag.secondaryPlayer})</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            <Button onClick={handleClickUpdate} variant='outlined'>Save</Button>
        </Box>
    );
}