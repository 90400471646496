import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';



export default function ChartSelect({chart, setChart}) {

    
    const handleChange = (event) => {
      setChart(event.target.value);
    };

    return (
                <FormControl sx={{ m: 1, width: 300 }}>
                    <InputLabel id="demo-simple-select-label">Chart</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={chart}
                        label="Chart Type"
                        onChange={handleChange}
                    >
                        <MenuItem value={"List-Volume"}>List: Volume</MenuItem>
                        <MenuItem value={"List-Speed"}>List: Speed</MenuItem>
                        <MenuItem value={"List-Intensity"}>List: Intensity</MenuItem>
                        <MenuItem value={"List-Stress"}>List: Stress</MenuItem>
                        <MenuItem value={"List-Min"}>List: Minimum</MenuItem>
                        <MenuItem value={"Volume"}>Chart: Volume</MenuItem>
                        <MenuItem value={"Speed"}>Chart: Speed</MenuItem>
                        <MenuItem value={"Intensity"}>Chart: Intensity</MenuItem>
                        <MenuItem value={"Stress"}>Chart: Stress</MenuItem>
                    </Select>
                </FormControl>
    );
}

