import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { useState } from 'react';
import Button from '@mui/material/Button';
import StatList from './StatList';
import VolumeChart from './VolumeChart';
import SpeedChart from './SpeedChart';
import IntensityChart from './IntensityChart';
import StressChart from './StressChart';

export default function ChartBox({ chart, sessions, players }) {

    console.log(players);

    const [chartParams, setChartParams] = useState({ chart: "", sessions: [], players: [] });

    const refreshChart = chart === chartParams.chart && sessions === chartParams.sessions && players === chartParams.players;
    console.log(refreshChart);

    const handleClick = (event) => {
        setChartParams({ chart: chart, sessions: sessions, players: players });
    };

    return (
        <Box
            sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            {process.env.REACT_APP_DEBUG === "ON" &&
                <Box>
                    <Typography>Chart: {chart}</Typography>
                    <Typography>Session: {sessions.length}</Typography>
                    <Typography>Players: {players.length}</Typography>
                </Box>
            }
            {refreshChart ?
                <Typography>Refreshed: {refreshChart ? 'True' : 'False'}</Typography>
                :
                <Button onClick={handleClick} variant="outlined">Refresh</Button>
            }
            {(refreshChart && (chartParams.chart === 'Volume'))
                &&
                <VolumeChart chartParams={chartParams} />
            }
            {(refreshChart && (chartParams.chart.startsWith('List')))
                &&
                <StatList chartParams={chartParams} />
            }
            {(refreshChart && (chartParams.chart === 'Speed'))
                &&
                <SpeedChart chartParams={chartParams} />
            }
            {(refreshChart && (chartParams.chart === 'Intensity'))
                &&
                <IntensityChart chartParams={chartParams} />
            }
            {(refreshChart && (chartParams.chart === 'Stress'))
                &&
                <StressChart chartParams={chartParams} />
            }
        </Box>
    );
}

