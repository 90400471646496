import { useQuery } from 'react-query';
import * as api from '../api/gaastats-api';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    Text,
    LabelList,
} from "recharts";


const YAxisLeftTick = ({ y, payload: { value } }) => {
    return (
        <Text x={0} y={y} textAnchor="start" verticalAnchor="middle"  >
            {value}
        </Text>
    );
};


export default function StressChart({ chartParams }) {
    console.log(JSON.stringify(chartParams));

    const { data: chartData, isLoading: isChartLoading, isError: isChartError } =
        useQuery(["data", chartParams], () => api.getData(chartParams.sessions, chartParams.players.toString()));

    if (isChartLoading) {
        return "Chart loading...";
    }

    if (isChartError) {
        return "Chart load error...";
    }

    const isMatch = () => {
        var res = false;
        res = chartParams.sessions.toString().includes('HOME') || chartParams.sessions.toString().includes('AWAY');
        return res;
    }

    const isMulti = () => {
        var res = false;
        res = chartParams.sessions.length > 1;
        return res;
    }

    const valueAccessor= (val) => {
        console.log(val);

        if (isMulti()) {
            if (isMatch()) {
                return `${val.SessionName}-${val.Drill==="First Half"?"1st":val.Drill==="Second Half"?"2nd":"Total"}`
            } else {
                return `${val.SessionName}`
            }
        } else {
            if (isMatch()) {
                return `${val.Drill}`
            } else {
                return "";
            }
        }
    }


    return (
        <ResponsiveContainer width="100%" height={800}>
            <BarChart
                layout="vertical"
                data={chartData}
                barCategoryGap={"5%"}
                barGap={"2%"}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis axisLine={false} type="number" />
                <YAxis dataKey="PlayerInitials"
                    type="category"
                    axisLine={true}
                    tickLine={true}
                    tick={YAxisLeftTick}
                />
                <Tooltip />
                <Legend />
                <Bar dataKey="StepBalanceL" fill="#82ca9d" />
                <Bar dataKey="StepBalanceR" fill="#9f0500" />
                <Bar dataKey="DSL" fill="#8884d8">
                    <LabelList valueAccessor={valueAccessor} position={"right"} />
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    );
}
